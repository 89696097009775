import { Buffer } from 'buffer'

export const getEncodedUrn = (urn: string) => {
  let _encodedUrn = ''
  if (urn) {
    _encodedUrn = Buffer.from(urn).toString('base64')
  }

  return _encodedUrn
}
