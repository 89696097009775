import { Subject } from 'rxjs'

interface ISubjectProps {
  action: 'success' | 'error' | 'loading' | 'update'
  toastProps: any
}

let SubjectNotifcation

export const addSubToNotification = (options: any) => {
  SubjectNotifcation = new Subject<ISubjectProps>()
  SubjectNotifcation.subscribe(options)
}

export const removeSubToNotification = () => {
  SubjectNotifcation.unsubscribe()
}

export const createNotification = (notifProps: ISubjectProps) => {
  SubjectNotifcation.next(notifProps)
}
