import {
  ApolloClient,
  ApolloClientOptions,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  ServerError,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const handleUnauthorized = onError(({ networkError }) => {
  const _netErro = networkError as ServerError;
  if (_netErro?.statusCode === 401) {
    localStorage.removeItem("token");
    window.location.reload();
  }
});

const apolloOptions: ApolloClientOptions<NormalizedCacheObject> = {
  cache: new InMemoryCache(),
  link: authLink.concat(
    handleUnauthorized.concat(
      new HttpLink({ uri: process.env.GRAPHQL_PLATFORM })
    )
  ),
};

const apolloOptionsSpp: ApolloClientOptions<NormalizedCacheObject> = {
  cache: new InMemoryCache(),
  link: authLink.concat(
    handleUnauthorized.concat(new HttpLink({ uri: process.env.GRAPHQL_SPP }))
  ),
};

export const getPlatformClient = () => {
  return new ApolloClient(apolloOptions);
};

export const getSppClient = () => {
  return new ApolloClient(apolloOptionsSpp);
};
