export const BASE_URL = process.env.BASE_URL
export const CUSTOMERS_URL = process.env.CUSTOMERS_URL
export const SALES_ORDERS_URL = process.env.SALES_ORDERS_URL
export const PRODUCT_URL = process.env.BOMS_URL
export const ITEMS_URL = process.env.ITEMS_URL
export const DOCS_URL = process.env.DOCS_URL
export const GATEWAY_URL = process.env.GATEWAY_URL
export const PURCHASE_ORDERS_URL = process.env.PURCHASE_ORDERS_URL
export const BOMS_URL = process.env.BOMS_URL

export const MECHANICAL_TYPE_ID: number = 1
export const ELECTRICAL_TYPE_ID: number = 2

export const LINK_TYPE_DOC: number = 1
export const LINK_TYPE_DWF: number = 2

export const HANDLEREVISION_DEFAULT: string = 'prop'
export const HANDLEREVISION_INCR: string = 'increment'

console.log(process.env)
