import jwtDecode from 'jwt-decode'
import axios from 'axios'
import { CUSTOMERS_URL } from './consts'

export const getUserInfo = () => {
  const token = localStorage.getItem('token')
  let _userInfo = null
  if (token) {
    _userInfo = jwtDecode(token)
  }
  return new Promise((resolve, reject) => {
    if (token) {
      axios
        .get(`${CUSTOMERS_URL}/auth/checktoken`)
        .then(() => {
          _userInfo = jwtDecode(token)
          resolve(_userInfo)
        })
        .catch(err => reject(err))
    } else {
      reject('no token')
    }
  })
}
