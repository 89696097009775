import { createTheme } from '@material-ui/core'

const palette = {
  primary: {
    light: 'rgba(17, 79, 142, 0.5)',
    main: '#114f8e',
  },
  secondary: {
    light: 'rgba(253, 184, 19, 0.5)',
    main: '#fdb813',
  },
}

export const theme = createTheme({ palette })
