import Axios from 'axios'

const token = localStorage.getItem('token')
if (token) {
  Axios.defaults.headers.common['Authorization'] = 'JWT ' + token
}

Axios.interceptors.response.use(
  res => {
    return res
  },
  error => {
    if (error.response?.status !== 401) {
      return new Promise((resolve, reject) => {
        reject(error)
      })
    }

    localStorage.removeItem('token')
    if (!window.location.pathname.includes('login')) window.location.reload()

    return new Promise((resolve, reject) => {
      reject(error)
    })
  }
)

export const axiosClient = Axios
