// Anything exported from this file is importable by other in-browser modules.
import { Subject } from 'rxjs'

let SubjectCart = new Subject<number>()

export const addSubToCart = (options: any) => {
  SubjectCart.subscribe(options)
}

export const removeSubToCart = () => {
  SubjectCart.unsubscribe()
  SubjectCart = new Subject<number>()
}

export const updateCart = (tot: number) => {
  SubjectCart.next(tot)
}
